import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import TestimonialCarousel from "./TestimonialCarousel";

const Hero = () => {
  return (
    <>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&family=Playfair+Display:ital,wght@0,400;0,700;1,400&display=swap');

          .old-paper {
            background-color: #e8dcc4;
            background-image: 
              radial-gradient(circle at 50% 50%, #e6d5b8 0%, transparent 100%),
              url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noise'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.15' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100' height='100' filter='url(%23noise)' opacity='0.15'/%3E%3C/svg%3E");
          }

          .paper-texture {
            background-image: 
              url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='paper'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='5'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23paper)' opacity='0.08'/%3E%3C/svg%3E"),
              repeating-linear-gradient(45deg, #d4c5a3 0px, #d4c5a3 1px, transparent 1px, transparent 5px);
          }

          .ornate-border {
            border-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0,0 L100,0 L100,100 L0,100 L0,0 M10,10 L90,10 L90,90 L10,90 L10,10' fill='none' stroke='%232c1810' stroke-width='1'/%3E%3C/svg%3E") 30;
            border-width: 20px;
            border-style: solid;
          }

          .title-font {
            font-family: 'UnifrakturMaguntia', serif;
            text-shadow: 2px 2px 0 #e8dcc4, 4px 4px 0 #2c1810;
          }

          .playfair {
            font-family: 'Playfair Display', serif;
          }

          .image-treatment {
            filter: sepia(80%) contrast(110%) brightness(90%);
            mix-blend-mode: multiply;
          }

          .vintage-button {
            transition: all 0.3s ease;
            background: linear-gradient(45deg, #2c1810, #3a2218);
          }

          .vintage-button:hover {
            transform: translate(-2px, -2px);
            box-shadow: 4px 4px 0 #2c1810;
          }

          .decorative-corner::before,
          .decorative-corner::after {
            content: '❧';
            font-size: 2rem;
            color: #2c1810;
            opacity: 0.6;
            position: absolute;
          }

          .decorative-corner::before {
            top: -1rem;
            left: -1rem;
          }

          .decorative-corner::after {
            bottom: -1rem;
            right: -1rem;
            transform: rotate(180deg);
          }
        `}
      </style>

      <div className="min-h-screen old-paper py-12">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="container mx-auto px-4"
        >
          <div
            className="max-w-7xl mx-auto relative bg-[#f8f1e3] ornate-border paper-texture 
                        desktop:p-16 laptop:p-12 tablet:p-8 mobile:p-6 shadow-[0_0_35px_rgba(0,0,0,0.1)]"
          >
            {/* Top Banner */}
            <div className="text-center mb-12">
              <motion.div
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ delay: 0.5, type: "spring" }}
              >
                <div className="flex justify-between items-center text-[#2c1810] mb-8">
                  <div className="playfair italic">
                    <p className="desktop:text-4xl laptop:text-3xl tablet:text-2xl mobile:text-xl font-bold">
                      Année 1620
                    </p>
                    <p className="text-lg">No. XXIII</p>
                  </div>
                  <div className="playfair italic">
                    <p className="text-sm">Prix: Deux Sols</p>
                    <p className="text-lg">15 Mai</p>
                  </div>
                </div>

                <h1
                  className="title-font desktop:text-[10rem] laptop:text-[8rem] tablet:text-7xl mobile:text-5xl 
                             text-[#2c1810] leading-none mb-6"
                >
                  La Gazette
                </h1>

                <div className="relative decorative-corner">
                  <h2
                    className="playfair desktop:text-4xl laptop:text-3xl tablet:text-2xl mobile:text-xl 
                               tracking-[0.4em] text-[#2c1810] font-bold border-t-2 border-b-2 border-[#2c1810] py-4"
                  >
                    DE PERPIGNAN
                  </h2>
                </div>

                <p className="playfair text-sm tracking-[0.3em] text-[#2c1810] mt-6 font-bold">
                  CHRONIQUES MENSUELLES DES ÉVÉNEMENTS REMARQUABLES
                </p>
              </motion.div>
            </div>

            <div className="desktop:flex laptop:flex tablet:block mobile:block gap-16">
              {/* Main Image Section */}
              <motion.div
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.8 }}
                className="desktop:w-7/12 laptop:w-7/12 tablet:w-full mobile:w-full"
              >
                <div className="relative">
                  <div className="absolute inset-0 border-[8px] border-[#2c1810] opacity-20"></div>
                  <img
                    src="https://createsiteai.b-cdn.net/c/_/d76ebe12-cb9a-4c0c-a71e-dbd914dc8afa"
                    alt="Perpignan Historical Scene"
                    className="w-full h-auto image-treatment"
                  />
                  <div
                    className="absolute bottom-0 left-0 right-0 bg-[#2c1810] bg-opacity-95 
                                text-[#f2e8d5] p-4 playfair italic text-sm text-center"
                  >
                    La Citadelle Royale de Perpignan • Vue du Grand Bastion
                  </div>
                </div>
              </motion.div>

              {/* Main Content */}
              <motion.div
                initial={{ opacity: 0, x: 30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 1 }}
                className="desktop:w-5/12 laptop:w-5/12 tablet:w-full mobile:w-full"
              >
                <div className="bg-[#2c1810] p-4 mb-8">
                  <h3 className="playfair text-2xl tracking-widest text-center text-[#f2e8d5] font-bold">
                    PROCLAMATION
                  </h3>
                </div>

                <div className="space-y-8 text-[#2c1810] playfair">
                  <p
                    className="text-xl leading-relaxed first-letter:text-8xl 
                               first-letter:font-bold first-letter:mr-3 first-letter:float-left"
                  >
                    Par la présente, nous annonçons la création d'une chronique
                    mensuelle relatant les événements notables de notre noble
                    cité de Perpignan. Ces pages témoigneront des faits
                    remarquables, des décisions du Conseil des Consuls, et des
                    traditions de notre peuple.
                  </p>

                  <p className="text-lg leading-relaxed italic">
                    "Chaque édition sera enrichie d'illustrations authentiques
                    et de témoignages directs de nos concitoyens."
                  </p>

                  <div className="text-center mt-12">
                    <Link
                      to="/news"
                      className="vintage-button inline-block text-[#f2e8d5] 
                               px-12 py-6 playfair text-xl tracking-widest border-2 border-[#2c1810]"
                    >
                      VOIR LES NOUVELLES
                    </Link>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Footer */}
            <div className="mt-16 border-t-2 border-[#2c1810] pt-8">
              <div className="text-center playfair italic text-sm tracking-wider text-[#2c1810]">
                Imprimé sur les Presses de l'Imprimerie Royale de Perpignan
                <div className="mt-2 text-xs">Avec Privilège du Roy</div>
              </div>
            </div>
          </div>
          <TestimonialCarousel />
        </motion.div>
      </div>
    </>
  );
};

export default Hero;
