import { motion } from "framer-motion";

const News = () => {
  const news = [
    {
      id: 1,
      date: "15 Mai 1620",
      title: "Le Conseil des Consuls Annonce de Nouveaux Travaux",
      content:
        "Les honorables Consuls de notre cité ont décrété ce jour la rénovation des remparts sud, assurant ainsi une meilleure protection de nos concitoyens.",
    },
    {
      id: 2,
      date: "14 Mai 1620",
      title: "CONFRERIE DES SASTRES",
      content:
        "Le grand livre de la confrérie est publié. Thierry pourra s'en servir comme source principale de son master CCS en l'an de grâce 2024.",
    },
    {
      id: 3,
      date: "13 Mai 1620",
      title: "Visite du Gouverneur",
      content:
        "Son Excellence le Gouverneur du Roussillon honorera notre ville de sa présence lors des célébrations du mois prochain.",
    },
    {
      id: 4,
      date: "12 Mai 1620",
      title: "Nouvelle Ordonnance sur les Tavernes",
      content:
        "Le Conseil Municipal a promulgué une ordonnance régulant les heures d'ouverture des tavernes, fixant la fermeture à la dixième heure du soir.",
    },
    {
      id: 5,
      date: "11 Mai 1620",
      title: "Arrivée des Marchands Vénitiens",
      content:
        "Une délégation de marchands vénitiens est arrivée hier, apportant des soieries et épices exotiques pour notre marché.",
    },
    {
      id: 6,
      date: "10 Mai 1620",
      title: "Récolte Prometteuse Annoncée",
      content:
        "Les vignerons de la région annoncent une récolte exceptionnelle pour cette année, promettant un vin de grande qualité.",
    },
    {
      id: 7,
      date: "9 Mai 1620",
      title: "Nouveau Maître Artisan",
      content:
        "La guilde des orfèvres accueille un nouveau maître artisan, le sieur Jacques Dupuis, reconnu pour son travail remarquable.",
    },
    {
      id: 8,
      date: "8 Mai 1620",
      title: "Célébration de la Saint-Jean",
      content:
        "Les préparatifs de la Saint-Jean battent leur plein, avec l'érection d'un nouveau mât sur la place centrale.",
    },
    {
      id: 9,
      date: "7 Mai 1620",
      title: "Nomination d'un Nouveau Juge",
      content:
        "Le Parlement de Perpignan accueille un nouveau juge, Maître Pierre de Montfort, réputé pour sa sagesse et son équité.",
    },
    {
      id: 10,
      date: "6 Mai 1620",
      title: "Rénovation de la Cathédrale",
      content:
        "Les travaux de restauration de la cathédrale Saint-Jean progressent rapidement, grâce aux généreux dons des fidèles.",
    },
    {
      id: 11,
      date: "5 Mai 1620",
      title: "Alliance Commerciale",
      content:
        "Une nouvelle alliance commerciale a été signée avec les marchands de Toulouse, promettant une prospérité accrue.",
    },
    {
      id: 12,
      date: "4 Mai 1620",
      title: "Découverte Archéologique",
      content:
        "Des vestiges romains ont été mis au jour lors des travaux de fondation près des remparts est de la ville.",
    },
    {
      id: 13,
      date: "3 Mai 1620",
      title: "Nouveau Système de Fontaines",
      content:
        "Le Conseil annonce l'installation de nouvelles fontaines publiques pour améliorer l'approvisionnement en eau.",
    },
    {
      id: 14,
      date: "2 Mai 1620",
      title: "Exposition d'Art Sacré",
      content:
        "Le couvent des Dominicains organise une exposition exceptionnelle d'art sacré, ouverte à tous les fidèles.",
    },
  ];

  return (
    <div className="min-h-screen old-paper py-12">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="container mx-auto px-4"
      >
        <div className="max-w-7xl mx-auto relative bg-[#f8f1e3] ornate-border paper-texture desktop:p-16 laptop:p-12 tablet:p-8 mobile:p-6">
          <div className="text-center mb-12">
            <h1 className="title-font desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl text-[#2c1810] mb-6">
              Les Nouvelles
            </h1>
            <p className="playfair text-sm tracking-[0.3em] text-[#2c1810] font-bold">
              CHRONIQUES DU JOUR
            </p>
          </div>

          <div className="space-y-12">
            {news.map((item) => (
              <motion.article
                key={item.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * item.id }}
                className="border-b-2 border-[#2c1810] pb-8 last:border-b-0"
              >
                <div className="playfair italic text-sm text-[#2c1810] mb-2">
                  {item.date}
                </div>
                <h2
                  className={`playfair text-2xl font-bold text-[#2c1810] mb-4 ${item.id === 2 ? "animate-blink" : ""}`}
                >
                  {item.title}
                </h2>
                <p className="playfair text-lg text-[#2c1810]">
                  {item.content}
                </p>
              </motion.article>
            ))}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default News;
