import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const testimonials = [
  {
    id: 25,
    name: "Dame De Jean",
    role: "Professeur des universités, Exploratrice",
    image:
      "https://createsiteai.b-cdn.net/c/_/f662b63d-bafc-4cb5-a73f-7782e6d48d82",
    quote:
      "La connaissance ne doit point être limitée par les conventions. Le travail et la transmission sont nos devoirs pour l'avenir.",
  },
  {
    id: 26,
    name: "Marcus Conesum",
    role: "Docteur en droit d'université",
    image:
      "https://createsiteai.b-cdn.net/c/_/b014fbd0-5173-4203-926e-cdcc553eadd6",
    quote:
      "Les lois de la nature surpassent celles des hommes en beauté et en sagesse.",
  },
  {
    id: 24,
    name: "Guillem dels aires",
    role: "Inventeur de machines volantes",
    image:
      "https://createsiteai.b-cdn.net/c/_/826e75c9-f6c2-4502-b0c7-7659c6307d37",
    quote:
      "Les cieux nous appellent, et nos machines nous y porteront un jour.",
  },
  {
    id: 20,
    priority: 3,
    name: "Don Thierry",
    role: "Maître artisan Sastre",
    image:
      "https://createsiteai.b-cdn.net/c/_/ba031191-254e-4487-b19c-77db16737964",
    quote:
      "La tradition du métier se transmet de génération en génération, comme un fil précieux qui traverse les âges.",
  },
  {
    id: 2,
    priority: 1,
    name: "Juius Luganum",
    role: "Enseignant artiste",
    image:
      "https://createsiteai.b-cdn.net/c/_/df05255a-13a4-46e5-8880-dacff3165222",
    quote:
      "L'art et l'enseignement sont les piliers de notre société civilisée, ils nous montrent la lumière.",
  },
  {
    id: 16,
    priority: 2,
    name: "Maria Loua",
    role: "Artiste peintre",
    image:
      "https://createsiteai.b-cdn.net/c/_/edc2004c-542f-46e9-b151-3be233973520",
    quote: "L'art nous permet de capturer l'essence de notre époque.",
  },
  {
    id: 2,
    name: "Juius Luganum",
    role: "Docteur en Théologie",
    image:
      "https://createsiteai.b-cdn.net/c/_/df05255a-13a4-46e5-8880-dacff3165222",
    quote:
      "Les écritures nous guident vers la vérité éternelle, comme une lumière dans l'obscurité.",
  },
  {
    id: 15,
    name: "Christobal",
    role: "seigneur des Hortolans",
    image:
      "https://createsiteai.b-cdn.net/c/_/40660340-7465-4b98-b7e5-63b3b784a87f",
    quote: "Les jardins de la ville sont le reflet de notre âme collective.",
  },
  {
    id: 16,
    name: "Maria Loua",
    role: "Artiste peintre",
    image:
      "https://createsiteai.b-cdn.net/c/_/edc2004c-542f-46e9-b151-3be233973520",
    quote: "L'art nous permet de capturer l'essence de notre époque.",
  },
  {
    id: 17,
    name: "Paolus",
    role: "Soldat du futur empereur",
    image:
      "https://createsiteai.b-cdn.net/c/_/22cc3baa-1af3-4f3c-9c4a-017c66d88f2c",
    quote: "La protection de notre cité est mon devoir sacré.",
  },
  {
    id: 18,
    name: "Anaïsa Gomalum",
    role: "Trafiquante d'armes",
    image:
      "https://createsiteai.b-cdn.net/c/_/c79929df-14ab-4977-ab17-76f9a06c74f9",
    quote: "Les affaires prospèrent dans l'ombre des remparts.",
  },
  {
    id: 19,
    name: "Maître Martinus",
    role: "Docteur en Philosophie",
    image:
      "https://createsiteai.b-cdn.net/c/_/b4983f08-f562-4d5c-a125-f67453f767ef",
    quote: "La sagesse nous guide vers la vérité éternelle.",
  },
  {
    id: 1,
    name: "Joana Lefbvrum",
    role: "Juge de la Gabelle",
    image:
      "https://createsiteai.b-cdn.net/c/_/c2040c42-a35f-4a57-ab08-e20a82fcaa12",
    quote:
      "La justice doit être rendue avec sagesse et équité, particulièrement en matière de gabelle.",
  },
  {
    id: 2,
    name: "Juius Luganum",
    role: "Enseignant artiste",
    image:
      "https://createsiteai.b-cdn.net/c/_/df05255a-13a4-46e5-8880-dacff3165222",
    quote:
      "L'art et l'enseignement sont les piliers de notre société civilisée, ils nous montrent la lumiére.",
  },
  {
    id: 3,
    name: "l'illustre Hamzamus",
    role: "Consul de la Ville",
    image:
      "https://createsiteai.b-cdn.net/c/_/fcba554e-1397-41f0-bc97-b4b441865d4a",
    quote:
      "Notre belle cité prospère grâce à la sagesse de ses institutions et la diligence de ses citoyens.",
  },
  {
    id: 1,
    name: "Jean-Pierre Dupont",
    role: "Marchand de Draps",
    image:
      "https://createsiteai.b-cdn.net/c/_/62e941ca-4ad6-49f5-9011-6d9ad1947f87",
    quote:
      "Cette gazette est une source précieuse d'informations pour notre communauté marchande.",
  },
  {
    id: 2,
    name: "Marie-Claire Rousseau",
    role: "Aubergiste",
    image:
      "https://createsiteai.b-cdn.net/c/_/c22c1c9f-8f76-4262-9871-6bff403db698",
    quote:
      "Les nouvelles fraîches de la ville me permettent de divertir mes clients avec les derniers événements.",
  },
  {
    id: 3,
    name: "François de Montclair",
    role: "Noble",
    image:
      "https://createsiteai.b-cdn.net/c/_/b67614e4-c99e-400e-a18f-ceac3dbb78a8",
    quote:
      "Une lecture indispensable pour tout homme de qualité qui se respecte.",
  },
  ,
  {
    id: 21,
    name: "Petri-Antonio",
    role: "Savant",
    image:
      "https://createsiteai.b-cdn.net/c/_/70c1aaaa-c29e-4170-b4b8-306e4554c992",
    quote:
      "La connaissance est le plus précieux des trésors que l'on puisse amasser.",
  },
  {
    id: 22,
    name: "Lucia Mona",
    role: "Protectrice des minorités",
    image:
      "https://createsiteai.b-cdn.net/c/_/80f012dc-df1a-435a-9e8b-fa446c3fa6e8",
    quote:
      "La justice et l'équité doivent s'étendre à tous les membres de notre communauté.",
  },
  {
    id: 23,
    name: "Alecum",
    role: "Scribe principal",
    image:
      "https://createsiteai.b-cdn.net/c/_/38b1f4b9-5ce5-4ae6-8415-8aa5a2b8935c",
    quote:
      "Les mots que nous écrivons aujourd'hui seront l'histoire de demain.",
  },
];

const TestimonialCarousel = () => {
  const testimonialsMap = new Map();

  testimonials.forEach((testimonial) => {
    const existing = testimonialsMap.get(testimonial.name);
    if (
      !existing ||
      (testimonial.priority &&
        (!existing.priority || testimonial.priority < existing.priority))
    ) {
      testimonialsMap.set(testimonial.name, testimonial);
    }
  });
  const uniqueTestimonials = Array.from(testimonialsMap.values()).filter(
    (testimonial) =>
      testimonial.name !== "Jean-Pierre Dupont" &&
      testimonial.name !== "Marie-Claire Rousseau" &&
      testimonial.name !== "François de Montclair" &&
      testimonial.name !== "Maître Martinus" &&
      testimonial.name !== "Marcus Conesum" &&
      testimonial.name !== "Dame De Jean" &&
      testimonial.name !== "Juius Luganum",
  );

  const sortedTestimonials = uniqueTestimonials.sort((a, b) => {
    if (a.priority && b.priority) {
      return a.priority - b.priority;
    }
    if (a.priority) return -1;
    if (b.priority) return 1;
    return 0;
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1.2 }}
      className="mt-16 paper-texture"
    >
      <div className="bg-[#2c1810] p-4 mb-8 max-w-2xl mx-auto">
        <h3 className="playfair text-2xl tracking-widest text-center text-[#f2e8d5] font-bold">
          TÉMOIGNAGES DE NOS LECTEURS
        </h3>
      </div>

      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 8000 }}
        className="testimonial-swiper max-w-4xl mx-auto"
      >
        {sortedTestimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="text-center p-8">
              <div className="w-32 h-32 mx-auto mb-6 relative">
                <div className="absolute inset-0 border-[4px] border-[#2c1810] opacity-20"></div>
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-full h-full object-cover image-treatment"
                />
              </div>
              <p className="playfair text-xl italic text-[#2c1810] mb-6">
                "{testimonial.quote}"
              </p>
              <div className="playfair text-[#2c1810]">
                <p className="font-bold">{testimonial.name}</p>
                <p className="text-sm">{testimonial.role}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  );
};

export default TestimonialCarousel;
